import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

import zhCN from "@arco-design/web-vue/es/locale/lang/zh-cn";
import { darkTheme } from "naive-ui";
import type { GlobalTheme } from "naive-ui";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();

const theme = ref<GlobalTheme | null>(darkTheme);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!

  return (_openBlock(), _createBlock(_component_n_message_provider, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_config_provider, { locale: _unref(zhCN) }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, { name: "app_view" }, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_KeepAlive, null, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ], 1024))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["locale"])
    ]),
    _: 1
  }))
}
}

})